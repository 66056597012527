import React from "react";
import useGetImage from "./useGetImage";
import Img from "gatsby-image";
import s from "./Content.module.scss";
import IframeBox from "../../../../components/iframeBox";
const Content = () => {
    const { firstImage, secondImage, thirdImage, fourthImage, fifthImage, } = useGetImage();
    return (<section className={s.box}>
      <h2 className={s.title}>Current Innovations</h2>
      <p className={s.text}>
        VGF works collaboratively with growers and brewers alike to identify
        areas of improvement in the industry. Our innovations are geared toward
        streamlining processes both on the farm and in the brewhouse. Listed
        below are some of the current innovations we have sponsored.
      </p>
      <div className={s.item}>
        <Img className={s.img} fluid={firstImage.childImageSharp.fluid}/>
        <div className={s.textBox}>
          <div>
            <h2 className={s.title}>HopTechnics™ Lot Selection App</h2>
            <p className={s.text}>
              A tool designed with the brewer in mind, making it easier than
              ever for an off-premise brewer to select hops. The innovative
              brewer can select from individual hop lots with nuanced aroma
              driven by terroir and harvest maturity window.
            </p>
          </div>
        </div>
      </div>
      <div className={s.item}>
        <Img className={s.img} fluid={secondImage.childImageSharp.fluid}/>
        <div className={s.textBox}>
          <div>
            <h2 className={s.title}>HopExpress™</h2>
            <p className={s.text}>
              A multi-faceted innovation featuring a flexible package design
              geared toward the emerging craft and homebrew markets. As an added
              bonus, our innovations team has refined the extraction process to
              create a product that is flowable at or below 20°C.
            </p>
          </div>
        </div>
      </div>
      <div className={s.item}>
        <Img className={s.img} fluid={thirdImage.childImageSharp.fluid}/>
        <div className={s.textBox}>
          <div>
            <h2 className={s.title}>US11445662B2</h2>
            <p className={s.text}>
              A very specific but important update to the heart and soul of
              every hop harvester; the picking finger. Our engineering changes
              made to the design doubles the picking finger life in our unique
              harvesters and significantly decreases the amount of maintenance
              required during the harvest season.
            </p>
          </div>
        </div>
      </div>
      <div className={s.item}>
        <Img className={s.imgPlane} fluid={fourthImage.childImageSharp.fluid}/>
        <div className={s.textBox}>
          <div>
            <h2 className={s.title}>Ale Plane</h2>
            <p className={s.text}>
              A microjet built by Bernard Gamache and piloted by Lt. Col. Justin
              Lewis. Check it out in flight on the airshow circuits or on the
              link below.
            </p>
            <IframeBox url="https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Foklahomaaero%2Fvideos%2F1253618911775456%2F&show_text=false&width=560&t=0"/>
          </div>
        </div>
      </div>
      <div className={s.item}>
        <Img className={s.img} fluid={fifthImage.childImageSharp.fluid}/>
        <div className={s.textBox}>
          <div>
            <h2 className={s.title}>
              Sometimes innovation is just folding some paper.... Stay tuned for
              our next projects!
            </h2>
          </div>
        </div>
      </div>
    </section>);
};
export default Content;
