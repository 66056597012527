import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      firstImage: file(
        relativePath: { eq: "images/growing_hops/growing_hops_1.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondImage: file(
        relativePath: { eq: "images/growing_hops/growing_hops_2.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thirdImage: file(
        relativePath: { eq: "images/growing_hops/growing_hops_3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fourthImage: file(
        relativePath: { eq: "images/growing_hops/growing_hops_4.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fifthImage: file(
        relativePath: { eq: "images/growing_hops/growing_hops_5.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    return data;
};
