import React from "react";
import GrowingHops from "../scenes/growingHops";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"Growing Hops"} keywords={keywords}/>
      <GrowingHops />
    </>);
};
